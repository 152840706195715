<template>
  <v-card>
    <div class="px-lg-3 pt-5">
      <v-card-text :class="{ 'text-sm mb-2': $vuetify.breakpoint.smAndDown }">
        <v-row>
          <v-col cols="12" sm="8" md="6" lg="12">
            <p class="text-base mt-lg-4" :class="{ 'text-sm mb-2': $vuetify.breakpoint.smAndDown }">
              {{ $t('settingsChangeContractStatus') }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-row align="center">
        <v-col class="px-7" cols="12" sm="8" md="6">
          <v-select
            v-model="statusFilter"
            :items="statusOptions"
            label="Select Status"
            placeholder="Select Status"
            outlined
            clearable
            class="mb-5 me-3"
            @change="setStatus"
          ></v-select>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
import { ref, getCurrentInstance, watch } from '@vue/composition-api';
import {
  CONTRACT_TRANSLATION_MAP,
  CONTRACT_EXISTING,
  CONTRACT_ONBOARDED,
  CONTRACT_OFFER_REQUESTED,
  CONTRACT_OFFER_ACCEPTED,
  CONTRACT_APPLICATION,
  CONTRACT_SIGNED,
  CONTRACT_OPTED_OUT,
  CONTRACT_SUSPENDED,
} from '@/constants';

import { updateEmployeeContractStatus } from '../../api/employee';

export default {
  setup() {
    const vm = getCurrentInstance().proxy;
    const { contract } = vm.$store.state.auth;
    const statusFilter = ref('');
    const rerenderKey = ref(0);
    const statusOptions = ref([
      {
        value: CONTRACT_ONBOARDED,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_ONBOARDED]),
      },
      {
        value: CONTRACT_OFFER_REQUESTED,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_OFFER_REQUESTED]),
      },
      {
        value: CONTRACT_OFFER_ACCEPTED,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_OFFER_ACCEPTED]),
      },
      {
        value: CONTRACT_APPLICATION,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_APPLICATION]),
      },
      {
        value: CONTRACT_SIGNED,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_SIGNED]),
      },
      {
        value: CONTRACT_EXISTING,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_EXISTING]),
      },
      {
        value: CONTRACT_OPTED_OUT,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_OPTED_OUT]),
      },
      {
        value: CONTRACT_SUSPENDED,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_SUSPENDED]),
      },
    ]);

    const { userData } = vm.$store.getters;
    const setStatus = () => {
      updateEmployeeContractStatus(userData.id, statusFilter.value).then(() => {
        vm.$store.commit('showMessage', {
          text: 'successChangeStatus',
          color: 'success',
          timeout: '2000',
        });
        vm.$store.commit('setContract', {
          ...contract,
          contractStatus: statusFilter.value,
        });
        statusFilter.value = '';
      });
    };
    watch(
      () => vm.$store.state.app.currentLang,
      () => {
        statusOptions.value = [
          {
            value: CONTRACT_ONBOARDED,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_ONBOARDED]),
          },
          {
            value: CONTRACT_OFFER_REQUESTED,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_OFFER_REQUESTED]),
          },
          {
            value: CONTRACT_OFFER_ACCEPTED,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_OFFER_ACCEPTED]),
          },
          {
            value: CONTRACT_APPLICATION,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_APPLICATION]),
          },
          {
            value: CONTRACT_SIGNED,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_SIGNED]),
          },
          {
            value: CONTRACT_EXISTING,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_EXISTING]),
          },
          {
            value: CONTRACT_OPTED_OUT,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_OPTED_OUT]),
          },
          {
            value: CONTRACT_SUSPENDED,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_SUSPENDED]),
          },
        ];
        rerenderKey.value += 1;
        vm.$forceUpdate();
      },
    );
    return {
      statusOptions,
      statusFilter,
      setStatus,
      rerenderKey,
    };
  },
};
</script>
