<template>
  <v-card flat class="mt-lg-4">
    <ModalWindow :is-open="removeModal" warning>
      <template #content>
        <p class="text-base">{{ $t('doYouWishToContinue') }}</p>
      </template>
      <template #actions>
        <v-btn outlined color="primary" @click="removeModal = false">
          {{ $t('buttonCancel') }}
        </v-btn>
        <v-btn color="primary" @click="removeMethod">
          {{ $t('buttonConfirm') }}
        </v-btn>
      </template>
    </ModalWindow>
    <ModalWindow :is-open="success" success>
      <template #content>
        <p class="text-base">{{ $t('twoFactorSuccessMsg') }}</p>
      </template>
      <template #actions>
        <v-btn color="primary" @click="success = false">
          {{ $t('buttonConfirm') }}
        </v-btn>
      </template>
    </ModalWindow>
    <div class="px-lg-3 pt-5">
      <v-card-text class="text-base">
        <v-row>
          <v-col cols="12" sm="8" md="6" lg="12">
            <p v-if="twoFactorMethod" class="mt-5" :class="{ 'text-sm mb-2': $vuetify.breakpoint.smAndDown }">
              {{ $t('twoFactorMethodInUseIntro') }}
            </p>
            <p v-else class="mt-5" :class="{ 'text-sm mb-2': $vuetify.breakpoint.smAndDown }">
              {{ $t('aut2faSetupIntro') }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="twoFactorMethod">
        <v-row>
          <v-col cols="12" sm="8" md="6">
            <!-- 2fa init -->
            <v-form @submit.prevent="sendConfirmCode">
              <div class="d-flex flex-column">
                <vue-tel-input-vuetify
                  v-model="oldPhone"
                  :label="$t('auth2faOldNumber')"
                  dense
                  readonly
                  disabledFetchingCountry
                  class="flex two-factor-auth__field"
                  :inputOptions="{ showDialCode: true }"
                  outlined
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </vue-tel-input-vuetify>
                <div>
                  <v-btn color="error" class="flex" :block="$vuetify.breakpoint.smAndDown" @click="openRemoveModal">
                    {{ $t('remove') }}
                  </v-btn>
                </div>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-else>
        <v-row>
          <v-col cols="12" sm="8" md="6">
            <!-- 2fa init -->
            <v-form ref="twoFactorForm" v-model="isNumberValid" @submit.prevent="sendConfirmCode">
              <div class="d-flex flex-column">
                <vue-tel-input-vuetify
                  v-model="phone"
                  validate-on-blur
                  :label="$t('auth2faNewNumber')"
                  defaultCountry="DE"
                  dense
                  :readonly="codeSent"
                  class="flex mr-2 two-factor-auth__field"
                  :rules="[validators.required, validators.phoneNumberValidator]"
                  :valid-characters-only="true"
                  :inputOptions="{ showDialCode: true }"
                  outlined
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </vue-tel-input-vuetify>
                <div class="mb-n2">
                  <v-btn
                    color="primary"
                    type="submit"
                    :disabled="!phone || codeSent"
                    :block="$vuetify.breakpoint.smAndDown"
                  >
                    {{ $t('numberVerify') }}
                  </v-btn>
                </div>
              </div>
            </v-form>
          </v-col>
        </v-row>

        <!-- step 2 -->

        <v-row>
          <v-col cols="12" sm="8" md="6" lg="12">
            <v-form v-if="codeSent" id="twoFactorConfirmForm" @submit.prevent="confirm">
              <v-card-text
                class="text-base px-0 mt-lg-5"
                :class="{ 'border-top text-sm': $vuetify.breakpoint.smAndDown }"
              >
                <v-row>
                  <v-col cols="12" sm="8" md="8" lg="12">
                    <p
                      v-if="timeLeft === 0"
                      class="error--text"
                      :class="{
                        'pt-5 text-sm mb-2': $vuetify.breakpoint.smAndDown,
                      }"
                    >
                      {{ $t('auth2faSetupExpired1') }}
                      {{ $t('auth2faSetupExpired2') }}
                    </p>
                    <p
                      v-else
                      :class="{
                        'pt-5 text-sm mb-2': $vuetify.breakpoint.smAndDown,
                      }"
                    >
                      {{ $t('auth2faSetupInstructions1') }}. {{ $t('auth2faSetupInstructions2') }} {{ timeLeft }}
                      {{ $t('auth2faSetupInstructions3') }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-row>
                <v-col cols="12" sm="8" md="6">
                  <v-text-field
                    v-model="code"
                    outlined
                    dense
                    :error="invalidCode"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :label="$t('auth2faConfirmCodePlaceholder')"
                    :placeholder="$t('auth2faConfirmCodePlaceholder')"
                    :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                    hide-details="auto"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                  <div class="float-right my-3">
                    <a
                      v-if="timeLeft === 0"
                      href=""
                      class="auth-resend-code text-sm text-decoration-none mr-2"
                      @click.prevent="resendCode"
                      >{{ $t('auth2faSendCode') }}</a
                    >
                  </div>
                  <div v-if="!twoFactorMethod">
                    <v-btn
                      type="submit"
                      :disabled="!code"
                      :block="$vuetify.breakpoint.smAndDown"
                      form="twoFactorConfirmForm"
                      color="primary"
                      class="mt-7 mb-3 mx-0"
                    >
                      {{ $t('codeVerify') }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import { ref, getCurrentInstance, onMounted, onBeforeUnmount, computed } from '@vue/composition-api';
import { required, phoneNumberValidator } from '@core/utils/validation';
import {
  confirm2fa,
  send2faConfirmCode,
  generate2faSecret,
  getRecoveryCodes,
  remove2faMethod,
  getMe,
} from '../../api/fusion-auth';
import ModalWindow from '../../components/modal/ModalWindow';

export default {
  components: { ModalWindow },
  setup() {
    const vm = getCurrentInstance().proxy;

    const phone = ref('');
    const oldPhone = ref('');
    const twoFactorForm = ref(null);
    const timeLeft = ref(60);
    const code = ref('');
    const secret = ref('');
    const timer = ref(null);
    const codeSent = ref(false);
    const isPasswordVisible = ref(false);
    const isNumberValid = ref(true);
    const invalidCode = ref(false);
    const success = ref(false);
    const removeModal = ref(false);
    const recoveryCodes = ref([]);
    const methodIds = ref([]);

    const user = vm.$store.getters.userData;
    const twoFactorId = computed(() => vm.$store.getters.twoFactorId);
    const twoFactorMethod = computed(() => vm.$store.getters.twoFactorMethod);

    const generateSecret = () => {
      generate2faSecret().then((res) => {
        secret.value = res.data.secretBase32Encoded;
      });
    };

    const decreaseTimeLeft = () => {
      if (timeLeft.value === 0) {
        clearInterval(timer.value);
      } else {
        timeLeft.value -= 1;
      }
    };

    const resendCode = () => {
      timeLeft.value = 60;
      sendConfirmCode();
    };

    const sendConfirmCode = () => {
      invalidCode.value = false;

      if (timeLeft.value === 0) return;

      const isPhoneValid = twoFactorForm.value.validate();
      if (!isPhoneValid) return;

      clearInterval(timer.value);
      codeSent.value = true;
      timeLeft.value = 60;
      timer.value = setInterval(decreaseTimeLeft, 1000);

      const formattedPhone = phone.value.replace(/\s+/g, '');

      send2faConfirmCode(formattedPhone, 'sms', user.id);
    };

    const confirm = () => {
      invalidCode.value = false;
      const formattedPhone = phone.value.replace(/\s+/g, '');

      confirm2fa(code.value, formattedPhone, secret.value)
        .then(() => {
          vm.$store.commit('setTwoFactorMethod', {
            method: 'sms',
            mobilePhone: formattedPhone,
          });

          retrieveRecoveryCodes();
          retrieveUserMethods();

          success.value = true;
          codeSent.value = false;
          phone.value = '';
          code.value = '';
          oldPhone.value = formattedPhone;
        })
        .catch(() => (invalidCode.value = true));
    };

    const openRemoveModal = () => {
      removeModal.value = true;
    };

    const retrieveRecoveryCodes = () => {
      getRecoveryCodes().then((res) => (recoveryCodes.value = res.data.recoveryCodes));
    };

    const retrieveUserMethods = () => {
      getMe().then((res) => {
        if (res.data.user.twoFactor && res.data.user.twoFactor.methods) {
          methodIds.value = res.data.user.twoFactor.methods.map((i) => i.id);
        }
      });
    };

    const removeMethod = () => {
      methodIds.value.forEach((id) => {
        remove2faMethod(user.id, recoveryCodes.value[0], id);
      });

      vm.$store.commit('setTwoFactorId', null);
      vm.$store.commit('setTwoFactorMethod', null);
      phone.value = '+49';
      removeModal.value = false;
    };

    onMounted(() => {
      generateSecret();
      if (twoFactorMethod.value) {
        oldPhone.value = twoFactorMethod.value.mobilePhone;
        retrieveRecoveryCodes();
        retrieveUserMethods();
      }
    });

    onBeforeUnmount(() => {
      clearInterval(timer.value);
      timer.value = null;
    });

    return {
      code,
      phone,
      oldPhone,
      isPasswordVisible,
      timeLeft,
      timer,
      twoFactorForm,
      codeSent,
      isNumberValid,
      success,
      invalidCode,
      removeModal,
      twoFactorId,
      twoFactorMethod,
      recoveryCodes,
      decreaseTimeLeft,
      sendConfirmCode,
      resendCode,
      confirm,
      openRemoveModal,
      removeMethod,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        phoneNumberValidator,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
  &__field {
    flex: 0 0 auto;
  }
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
