<template>
  <div id="account-setting-card" class="py-sm-10 mt-5">
    <v-row justify="center" class="trasparent">
      <v-col cols="12" sm="12" md="10" lg="10">
        <!-- tabs -->
        <v-tabs
          v-model="tab"
          show-arrows
          active-class="my-bav__tab--active"
          :grow="tabs.length > 3"
          :icons-and-text="!$vuetify.breakpoint.smAndDown"
          :hide-slider="$vuetify.breakpoint.smAndDown"
        >
          <v-tab v-for="tab in tabs" :key="tab.icon" class="v-tab">
            <div class="align-center justify-center mb-0">
              <v-icon v-if="!$vuetify.breakpoint.smAndDown" size="22" class="me-3">
                {{ tab.icon }}
              </v-icon>
              <span class="my-bav__tab-text">{{ $t(tab.title) }}</span>
            </div>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-5">
      <v-col cols="12" sm="12" md="10" lg="10">
        <v-card dark class="v-card--cc" style="border: none">
          <!-- tabs item -->
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <account-settings-account :account-data="accountSettingData.account"></account-settings-account>
            </v-tab-item>

            <v-tab-item v-if="!is2faDisabled">
              <account-settings-security></account-settings-security>
            </v-tab-item>

            <v-tab-item>
              <account-settings-cookies></account-settings-cookies>
            </v-tab-item>
            <v-tab-item>
              <account-settings-status></account-settings-status>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiLockReset,
  mdiCookie,
  mdiListStatus,
} from '@mdi/js';
import { ref, getCurrentInstance } from '@vue/composition-api';
import { config } from '../../../appConfig';

// demos
import AccountSettingsAccount from './AccountSettingsAccount.vue';
import AccountSettingsSecurity from './AccountSettingsSecurity.vue';
import AccountSettingsCookies from './AccountSettingsCookies.vue';
import AccountSettingsStatus from './AccountSettingsStatus.vue';

export default {
  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountSettingsCookies,
    AccountSettingsStatus,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const tab = ref('');
    const tabs = ref([]);

    const { activeRole } = vm.$store.state.auth;
    const is2faDisabled =
      activeRole === 'ROLE_KEY_ACCOUNT' || vm.$store.state.auth.companyData.settings2fa === 'DISABLE';

    config.value = config;

    tabs.value = [
      { title: 'changePassword', icon: mdiLockReset },
      ...(is2faDisabled ? '' : [{ title: 'aut2faSetupHeading', icon: mdiLockOpenOutline }]),

      { title: 'cookieConsentManagement', icon: mdiCookie },

      ...(config.variables.CURRENT_ENV === 'dev' ? [{ title: 'Change Status', icon: mdiListStatus }] : ''),
    ];

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        username: 'johnDoe',
        name: 'john Doe',
        email: 'johnDoe@example.com',
        role: 'Admin',
        status: 'Active',
        company: 'Google.inc',
      },
      information: {
        bio: 'The name’s John Deo. I am a tireless seeker of knowledge, occasional purveyor of wisdom and also, coincidentally, a graphic designer. Algolia helps businesses across industries quickly create relevant 😎, scaLabel 😀, and lightning 😍 fast search and discovery experiences.',
        birthday: 'February 22, 1995',
        phone: '954-006-0844',
        website: 'https://themeselection.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
      notification: {
        commentOnArticle: true,
        answerOnForm: true,
        followMe: false,
        newsAnnouncements: false,
        productUpdates: true,
        blogDigest: false,
      },
    };

    return {
      tab,
      tabs,
      accountSettingData,
      is2faDisabled,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiCookie,
        mdiListStatus,
      },
    };
  },
};
</script>
<style lang="scss">
.v-application.skin-variant--bordered .theme--dark.v-card--cc:not(.v-card .v-card) {
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 1px;
}
.my-bav {
  padding-top: 33px !important;

  .v-tabs-bar.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows)
    > .v-slide-group__wrapper
    > .v-tabs-bar__content
    > .v-tab:first-of-type {
    margin-left: unset !important;
  }

  .v-tabs-bar {
    height: 61px !important;
  }

  .v-tabs-items {
    background-color: transparent !important;
  }
  .v-tab {
    font-size: 14px !important;
    text-transform: none !important;
    padding-top: 13px;
    padding-bottom: 8px;
    letter-spacing: normal !important;
  }
  &-next {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
  }
  &__tab {
    &-text {
      color: #898989;
      margin-top: 9px;
      text-transform: none;
      display: inline-flex;
    }
    &--active {
      background-color: rgba(108, 189, 255, 0.5);
      font-weight: bold;
      .my-bav__tab-text {
        color: #2830ce;
        font-weight: bold;
      }
    }
  }
}
.v-tabs-overlay {
  position: relative;
}
.v-tabs-overlay::after {
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  z-index: 4;
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  pointer-events: none; /* Pass events through the "fade" layer */
}
.scrolling::after {
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .my-bav {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: flex !important;
      word-break: break-word;
      white-space: pre-wrap;
    }
    .v-tabs-bar {
      height: 50px !important;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }
    .v-tab {
      max-width: 50px;
      line-height: 12px !important;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
    }
    &__tab-text {
      max-width: 95px;
      font-size: 10px !important;
      margin: 0 !important;
    }
    &-tabs {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 959px) {
  .v-divider {
    transform: scale(0.4);
  }
  .v-stepper__step {
    padding: 24px 19px;
  }
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 110px;
  }
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    font-weight: 400;
    font-size: 12px !important;
    line-height: 15px;
    justify-content: center;
  }
  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__step__step {
      margin-right: 5px;
    }
  }
}
</style>
