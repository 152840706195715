<template>
  <v-card>
    <div class="px-lg-3 pt-5">
      <v-card-text class="d-flex px-0">
        <CookieConsentManage
          @acceptedNecessary="ccUpdateNecessary($event)"
          @acceptedAnalytics="ccUpdateAnalytics($event)"
        />
      </v-card-text>
      <v-card-text>
        <v-btn
          type="submit"
          color="primary"
          class="me-3 mb-3"
          :disabled="saveDisabled"
          :block="$vuetify.breakpoint.smAndDown"
          @click="ccSavePrefs"
        >
          {{ $t('buttonSaveCookie') }}
        </v-btn>
      </v-card-text>
    </div>
  </v-card>
  <!-- divider -->
  <!-- <v-divider></v-divider> -->
  <!-- action buttons -->
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCookie } from '@mdi/js';
import { ref, getCurrentInstance } from '@vue/composition-api';
import CookieConsent from '@/components/cookie-consent/CookieConsent.vue';
import CookieConsentManage from '@/components/cookie-consent/CookieConsentManage.vue';

export default {
  components: {
    CookieConsent,
    CookieConsentManage,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const acceptedNecessary = ref(vm.$store.getters.necessaryCookies);
    const acceptedAnalytics = ref(vm.$store.getters.analyticsCookies);
    const saveDisabled = ref(true);
    const ccUpdateNecessary = ($event) => {
      acceptedNecessary.value = $event.value;
      if (!$event.value) {
        acceptedAnalytics.value = false;
      }
      if ($event.fromGetters) {
        saveDisabled.value = true;
      } else {
        saveDisabled.value = false;
      }
    };
    const ccUpdateAnalytics = ($event) => {
      acceptedAnalytics.value = $event.value;
      acceptedNecessary.value = true;
      if ($event.fromGetters) {
        saveDisabled.value = true;
      } else {
        saveDisabled.value = false;
      }
    };
    const ccSavePrefs = () => {
      saveDisabled.value = true;
      if (!acceptedAnalytics.value && !acceptedNecessary.value) {
        vm.$cc.accept([]);
      } else {
        vm.$cc.accept(acceptedAnalytics.value === true ? 'all' : 'necessary');
      }
    };

    return {
      ccSavePrefs,
      acceptedNecessary,
      acceptedAnalytics,
      ccUpdateNecessary,
      ccUpdateAnalytics,
      saveDisabled,

      icons: {
        mdiCookie,
      },
    };
  },
};
</script>

<style lang="scss">
body .v-expansion-panel {
  position: relative;
}
.v-application.theme--light .v-expansion-panel.v-expansion-panel--custom.v-expansion-panel--active {
  box-shadow: none !important;
}

.v-expansion-panel.v-expansion-panel--custom::before {
  box-shadow: none !important;
}
.v-switch--custom {
  position: absolute;
  right: 40px;
  top: -7px;
  z-index: 10;
}
</style>
