<template>
  <v-card>
    <v-form ref="changePwForm" v-model="isFormValid" @submit.prevent="confirmPwChanging">
      <div class="px-lg-3 pt-5">
        <v-card-text class="text-base">
          <v-row>
            <v-col sm="8" md="6" lg="12">
              <p class="mt-lg-4" :class="{ 'text-sm mb-1': $vuetify.breakpoint.smAndDown }">
                {{ $t('changePassWordText') }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pb-2">
          <v-row>
            <v-col cols="12" md="7" order="2" order-md="1">
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                :rules="[validators.required]"
                :label="$t('formLabelOldPassword')"
                :placeholder="$t('formLabelOldPassword')"
                outlined
                dense
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                :label="$t('formLabelNewPassword')"
                :placeholder="$t('formPlaceholderNewPassword')"
                :rules="[validators.required, validators.simplePasswordValidator]"
                outlined
                dense
                persistent-hint
                class="my-md-3"
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                :label="$t('formPlaceholderRepeatPassword')"
                required
                :placeholder="$t('formPlaceholderRepeatPassword')"
                :rules="[validators.required, validators.passwordConfirmationValidator]"
                outlined
                dense
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="5" order="1" order-md="2" class="position-relative">
              <v-card class="mx-auto mb-1" max-width="324" outlined>
                <v-card-subtitle class="text-base font-weight-bold py-2">
                  {{ $t('errorPasswordTitle') }}
                </v-card-subtitle>

                <v-card-text class="pb-4">
                  <ul style="line-height: 28px">
                    <li>{{ $t('errorPasswordCharacters') }}</li>
                    <li>{{ $t('errorPasswordUpperCase') }}</li>
                    <li>{{ $t('errorPasswordLowerCase') }}</li>
                    <li>{{ $t('errorPasswordNumber') }}</li>
                    <li>{{ $t('errorPasswordSpecialCharacter') }}</li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- action buttons -->
        <v-card-text>
          <v-btn
            class="mb-3"
            type="submit"
            :disabled="!isFormValid"
            color="primary"
            :block="$vuetify.breakpoint.smAndDown"
          >
            {{ $t('buttonSavePassword') }}
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import { ref, getCurrentInstance } from '@vue/composition-api';
import { passwordValidator, simplePasswordValidator, required } from '../../@core/utils/validation';
import { CHANGE_PASSWORD } from '../../store/modules/auth/actions';

export default {
  setup() {
    const vm = getCurrentInstance().proxy;
    const isCurrentPasswordVisible = ref(false);
    const isNewPasswordVisible = ref(false);
    const isCPasswordVisible = ref(false);
    const currentPassword = ref('');
    const newPassword = ref('');
    const cPassword = ref('');
    const changePwForm = ref(null);
    const isFormValid = ref(false);

    const passwordConfirmationValidator = (c) => newPassword.value === c || vm.$t('errorPasswordMatch');

    const confirmPwChanging = () => {
      const isFormValid = changePwForm.value.validate();
      if (!isFormValid) return;

      vm.$store.dispatch(CHANGE_PASSWORD, {
        currentPassword: currentPassword.value,
        password: newPassword.value,
      });
      changePwForm.value.reset();
    };

    return {
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      changePwForm,
      isFormValid,
      confirmPwChanging,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      validators: {
        required,
        passwordValidator,
        passwordConfirmationValidator,
        simplePasswordValidator,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
